<template>
  <div :id="containerId">
    <component
      :is="selectedProvider.component"
      v-if="selectedProvider"
      :container-id="containerId"
      :field="field"
      :values="values"
      @update-payment-status="handlePaymentStatus"
    />
  </div>
</template>

<script >
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const containerId = computed(() => props.field.id ? 'payment-gateway-' + props.field.id : '');
  const serviceProviders = ref([{
    name: 'Element',
    component: defineAsyncComponent(() => import('./ElementGateway.vue'))
  }]);
  /**
   * @param {'SUCCESS' | 'FAILED' | 'INPROGRESS' | 'EXPIRED' | 'UNINITIATED'} newPaymentStatus 
   */

  const handlePaymentStatus = newPaymentStatus => {
    emit('update-payment-status', newPaymentStatus);
  };

  const selectedProvider = computed(() => props.field?.properties.basic.serviceProvider ? serviceProviders.value.find(provider => provider.name === props.field.properties.basic.serviceProvider) : null);
  return {
    containerId,
    handlePaymentStatus,
    selectedProvider
  };
};

export default __sfc_main;
</script>
